var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "collapse adm shadow", attrs: { "data-level": "1" } },
    [
      _c(
        "li",
        { attrs: { id: "desktop-menu" + _vm.categoryId } },
        [_c("loading-animation")],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }