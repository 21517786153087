var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.$slots.items && _vm.$slots.items.length > _vm.itemsPerPage
    ? _c(
        "div",
        {
          staticClass: "carousel slide",
          attrs: { id: "carousel" + _vm._uid, "data-interval": "false" },
        },
        [
          _c(
            "ol",
            { staticClass: "carousel-indicators" },
            _vm._l(_vm.$slots.items, function (item, index) {
              return index % _vm.itemsPerPage === 0
                ? _c("li", {
                    class: { active: index === 0 },
                    attrs: {
                      "data-target": "#carousel" + _vm._uid,
                      "data-slide-to": Math.floor(index / _vm.itemsPerPage),
                    },
                  })
                : _vm._e()
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "carousel-inner" },
            _vm._l(
              Math.ceil(_vm.$slots.items.length / _vm.itemsPerPage),
              function (n) {
                return _c(
                  "div",
                  { staticClass: "carousel-item", class: { active: n === 1 } },
                  [
                    _c(
                      "ul",
                      { staticClass: "row product-list mx-n2" },
                      _vm._l(_vm.$slots.items, function (item, index) {
                        return index >= (n - 1) * _vm.itemsPerPage &&
                          index < n * _vm.itemsPerPage
                          ? _c(
                              "li",
                              { class: "px-2 " + _vm.columnWidths },
                              [
                                _c("slot-component", {
                                  attrs: { vnode: item },
                                }),
                              ],
                              1
                            )
                          : _vm._e()
                      }),
                      0
                    ),
                  ]
                )
              }
            ),
            0
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "carousel-control-prev",
              attrs: {
                href: "#carousel" + _vm._uid,
                role: "button",
                "data-slide": "prev",
              },
            },
            [
              _c("span", {
                staticClass: "carousel-control-prev-icon",
                attrs: { "aria-hidden": "true" },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "sr-only" }, [_vm._v("Previous")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "carousel-control-next",
              attrs: {
                href: "#carousel" + _vm._uid,
                role: "button",
                "data-slide": "next",
              },
            },
            [
              _c("span", {
                staticClass: "carousel-control-next-icon",
                attrs: { "aria-hidden": "true" },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "sr-only" }, [_vm._v("Next")]),
            ]
          ),
        ]
      )
    : _c(
        "ul",
        { staticClass: "row product-list mx-n2" },
        _vm._l(_vm.$slots.items, function (item) {
          return _c(
            "li",
            { class: "px-2 " + _vm.columnWidths },
            [_c("slot-component", { attrs: { vnode: item } })],
            1
          )
        }),
        0
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }