<template>
    <tr v-if="upSellingItem" class="bx-narrative-item" :data-bx-item-id="'upselling|productid='+item.variation.id">
        <td class="align-middle">
            <a :href="item | itemURL(urlWithVariationId)">
                <lazy-img class="d-block xy-75" picture-class="img-fluid" :image-url="item.images | itemImages(imageUrlAccessor) | itemImage" :alt="item | itemName"></lazy-img>
            </a>
        </td>
        <td class="align-middle">
            <a :href="item | itemURL(urlWithVariationId)">
                {{ item | itemName }}<!--
                --><span v-for="attribute in item.groupedAttributes">{{ "Ceres::Template.itemGroupedAttribute" | translate(attribute) }}</span>
            </a>
        </td>
        <td class="align-middle text-right">
            <slot name="before-prices"></slot>
            <div
                class="badge badge-danger"
                v-if="item.prices.rrp && item.prices.rrp.price.value > 0 && item.prices.rrp.price.value > item.prices.default.unitPrice.value"
                v-html="'-' + Math.round(100 * (1 - (item.prices.default.unitPrice.value / item.prices.rrp.unitPrice.value))) + ' %'"></div>
            <span class="d-block old-price mb-1" v-if="item.prices.rrp && item.prices.rrp.price.value > 0 && item.prices.rrp.price.value > item.prices.default.unitPrice.value">{{ item.prices.rrp.price.value | currency }}</span>
            <b class="text-danger">{{ item.prices.default.unitPrice.value | currency }}</b>
            <slot name="after-prices"></slot>
        </td>
        <td class="align-middle">
            <div class="form-check">
                <input class="form-check-input bx-narrative-item bx-narrative-button" :data-bx-item-id="'checkbox|upselling='+item.variation.id" type="checkbox" :id="'upselling' + _uid" :checked="false" @change="onUpSellingSelected($event.target.checked)">
                <label class="form-check-label sr-only" :for="'upselling' + _uid">{{ item | itemName }} {{ $translate("Ceres::Template.singleItemAddToBasket") }}</label>
            </div>
        </td>
    </tr>
    <article class="cmp cmp-product-thumb bx-narrative-item" :data-bx-item-id="'productcard|productid='+item.variation.id" v-else>
        <div class="thumb-image prop-1-1">
            <slot name="item-image">
                <category-image-carousel :image-urls-data="item.images | itemImages(imageUrlAccessor)"
                                        :alt-text="item | itemName"
                                        :title-text="item | itemName"
                                        :item-url="item | itemURL(urlWithVariationId)"
                                        :enable-carousel="$ceres.config.item.enableImageCarousel"
                                        :disable-carousel-on-mobile="disableCarouselOnMobile"
                                        ref="categoryImageCarousel">
                </category-image-carousel>
            </slot>
        </div>

        <div class="special-tags p-2">
          <lazy-img ref="itemLazyImage" picture-class="xy-30 mr-2 border rounded" :image-url="item.item.manufacturer.logo" :data-alt="item.item.manufacturer.externalName"></lazy-img>
        </div>

        <!-- STORE SPECIALS -->
        <slot name="store-special">
            <item-store-special v-if="storeSpecial || item.variation.bundleType === 'bundle' || item.item.itemType === 'set'"
                                :store-special="storeSpecial"
                                :recommended-retail-price="item.prices.rrp"
                                :variation-retail-price="item.prices.default"
                                :special-offer-price="item.prices.specialOffer"
                                :decimal-count="decimalCount"
                                :bundle-type="item.variation.bundleType"
                                :item-type="item.item.itemType">
            </item-store-special>
        </slot>
        <!-- ./STORE SPECIALS -->

        <!-- ITEM DETAILS -->
        <slot name="item-details">
            <div class="thumb-content">
                <a :href="item | itemURL(urlWithVariationId)" class="thumb-title" :class="{ 'stretched-link': $ceres.config.global.shippingCostsCategoryId == 0 }">
                    {{ item | itemName }}<!--
                --><span v-for="attribute in item.groupedAttributes">{{ "Ceres::Template.itemGroupedAttribute" | translate(attribute) }}</span>
                </a>

                <div class="thumb-meta">
                    <slot name="before-prices"></slot>

                    <add-to-basket
                            :variation-id="item.variation.id"
                            :is-salable="!!item.filter && item.filter.isSalable"
                            :has-children="!!item.item && item.item.salableVariationCount > 1"
                            :interval-quantity="item.variation.intervalOrderQuantity || 1"
                            :minimum-quantity="item.variation.minimumOrderQuantity"
                            :maximum-quantity="!!item.variation.maximumOrderQuantity && item.variation.maximumOrderQuantity > 0 ? item.variation.maximumOrderQuantity : null"
                            :order-properties="item.properties.filter(function(prop) { return prop.property.isOderProperty })"
                            :has-order-properties="item.hasOrderProperties"
                            :has-required-order-property="item.hasRequiredOrderProperty"
                            :use-large-scale="false"
                            :show-quantity="false"
                            :item-url="item | itemURL(urlWithVariationId)"
                            :has-price="item | hasItemDefaultPrice"
                            :variation-price="item.prices.default.unitPrice.value"
                            :old-price="item.prices.rrp && item.prices.rrp.price.value > 0 && item.prices.rrp.price.value > item.prices.default.unitPrice.value ? item.prices.rrp.price.value : null"
                            :is-available="itemAvailable"
                            :availability-text="availabilityText"
                            :item-type="item.item.itemType">
                    </add-to-basket>
                </div>

                <slot name="after-prices"></slot>
            </div>
        </slot>
        <!-- ./ITEM DETAILS  -->
    </article>
</template>

<script>
import { mapState } from "vuex";
import CategoryImageCarousel from "./CategoryImageCarousel.vue";
import ItemStoreSpecial from "./ItemStoreSpecial.vue";
import {getSlotData} from "../../helper/getSlotData";

export default {
    components: {
        CategoryImageCarousel,
        ItemStoreSpecial
    },

    props: {
        decimalCount:
        {
            type: Number,
            default: 0
        },
        imageUrlAccessor:
        {
            type: String,
            default: "urlPreview"
        },
        itemData:
        {
            type: Object
        },
        disableCarouselOnMobile:
        {
            type: Boolean
        },
        paddingClasses:
        {
            type: String,
            default: null
        },
        paddingInlineStyles:
        {
            type: String,
            default: null
        },
        forceUrlWithVariationId:
        {
            type: Boolean,
            default: false
        },
        upSellingItem:
        {
            type: Boolean,
            default: false
        },
        upSellingSalesPriceId:
        {
            type: Number,
            default: 0
        }
    },

    jsonDataFields: [
        "itemDataRef"
    ],

    mounted()
    {
        if (this.upSellingItem && typeof this.$store.getters.currentItemVariation.upSellingItems === "undefined") {
            this.$store.getters.currentItemVariation.upSellingItems = new Set();
        }
    },

    computed:
    {
        item()
        {
            return this.itemData || this.itemSlotData || this.itemDataRef;
        },

        itemSlotData: getSlotData('item-data'),

        /**
         * returns itemData.item.storeSpecial
         */
        storeSpecial()
        {
            return this.item.item.storeSpecial;
        },

        /**
         * returns itemData.texts
         */
        texts()
        {
            return this.item.texts;
        },

        itemPrice()
        {
            return this.$options.filters.specialOffer(this.item.prices.default.unitPrice.formatted, this.item.prices, "unitPrice", "formatted" );
        },

        basePrice()
        {
            return this.item.prices.default.basePrice;
        },

        itemPriceGraduated()
        {
           let unitPrice;
           if (App.config.item.enableGraduatedPrices) {
             unitPrice = this.item.prices.graduatedPrices.length > 0
                 ? this.item.prices.graduatedPrices[0].unitPrice
                 : this.item.prices.default.unitPrice;
           } else {
             unitPrice = this.item.prices.default.unitPrice;
           }

            return this.$options.filters.specialOffer(unitPrice.formatted, this.item.prices, "unitPrice", "formatted" );
        },

        itemGraduatedPriceisCheapestSorting()
        {
            return !!this.item.item && this.item.item.salableVariationCount > 1 && !!this.$ceres.isCheapestSorting;
        },

        itemGraduatedPricesalableVariationCount()
        {
            return !!this.item.item && this.item.item.salableVariationCount == 1 && this.item.prices.graduatedPrices.length > 1 && App.config.item.enableGraduatedPrices;
        },

        itemSetPrice()
        {
            return this.$options.filters.currency(
                this.item.prices.default.price.value,
                this.item.prices.default.currency
            );
        },

        availabilityText()
        {
            let text = this.item.variation.availability && this.item.variation.availability.names && this.item.variation.availability.names.name;
            if (this.item.variation.availability && this.item.variation.availability.id === 2 && this.item.stock.net > 0) {
              text = "Sofort verfügbar";
            }
            else if (this.item.variation.releasedAt) {
              const today = new Date().getTime();
              const releaseDate = new Date(this.item.variation.releasedAt).getTime();
              const sanktNimmerlein = new Date(2030,11,31);
              if (releaseDate - today > 0) {
                if (Math.abs(sanktNimmerlein - releaseDate) < 86400000) {
                  text = "Liefertermin unbekannt";
                } else {
                  text = "Voraussichtlich verfügbar ab " + Vue.filter("date").apply(null, [this.item.variation.releasedAt, "DD.MM.YYYY"]);
                }
              }
            }
            return text;
        },

        itemAvailable()
        {
            return ((this.item.variation.availability.id === 2 && this.item.stock.net > 0) || this.item.variation.availability.id === 1);
        },

        urlWithVariationId()
        {
            return !this.$ceres.config.item.showPleaseSelect || this.$ceres.initialPleaseSelect == 0 || this.forceUrlWithVariationId;
        },

        hasCrossPrice() {
            const hasRrpPrice = !!this.item.prices.rrp &&
                this.item.prices.rrp.unitPrice.value > this.item.prices.default.unitPrice.value;

            const hasBeforePrice = !!this.item.prices.specialOffer &&
                !!this.item.prices.default &&
                this.item.prices.default.unitPrice.value > this.item.prices.specialOffer.unitPrice.value;

            return hasRrpPrice || hasBeforePrice;
        },

        ...mapState({
            showNetPrices: state => state.basket.showNetPrices
        })
    },

    methods:
    {
        onUpSellingSelected(value)
        {
            if (value) {
                this.$store.getters.currentItemVariation.upSellingItems.add(this);
            }
            else {
                this.$store.getters.currentItemVariation.upSellingItems.delete(this);
            }
        }
    }
}
</script>
