var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mobile-navigation-wrapper" }, [
    _c(
      "div",
      {
        staticClass: "mobile-navigation",
        class: { open: _vm.isMobileNavigationOpen },
      },
      [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isNavigationInitialized,
                expression: "isNavigationInitialized",
              },
            ],
          },
          [
            _c("ul", { staticClass: "breadcrumb d-block p-0 m-0" }, [
              _c(
                "li",
                {
                  staticClass: "px-3 bg-danger pull-right",
                  on: {
                    click: function ($event) {
                      return _vm.closeNavigation()
                    },
                  },
                },
                [
                  _c("i", {
                    staticClass: "fa fa-close",
                    attrs: { "aria-hidden": "true" },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  staticClass: "px-3 bg-secondary",
                  on: {
                    click: function ($event) {
                      return _vm.slideTo(null, true)
                    },
                  },
                },
                [
                  _c("i", {
                    staticClass: "fa fa-home",
                    attrs: { "aria-hidden": "true" },
                  }),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "ul",
              {
                directives: [{ name: "menu", rawName: "v-menu" }],
                staticClass: "mainmenu w-100 p-0 m-0 menu-active",
                attrs: { id: "menu-1" },
              },
              [
                _vm.dataContainer1.parent && _vm.dataContainer1.parent.details
                  ? _c(
                      "li",
                      {
                        staticClass: "ddown bg-secondary",
                        on: {
                          click: function ($event) {
                            return _vm.slideTo(
                              (_vm.dataContainer1.parent &&
                                _vm.dataContainer1.parent.parent) ||
                                null,
                              true
                            )
                          },
                        },
                      },
                      [
                        _vm._m(0),
                        _vm._v(" "),
                        _c("a", [
                          _vm._v(
                            _vm._s(_vm.dataContainer1.parent.details[0].name)
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.dataContainer1.parent && _vm.dataContainer1.parent.url
                  ? _c(
                      "li",
                      {
                        staticClass: "ddown bg-dark",
                        on: {
                          click: function ($event) {
                            return _vm.openCategory(
                              _vm.dataContainer1.parent.url
                            )
                          },
                        },
                      },
                      [_vm._m(1), _vm._v(" "), _vm._m(2)]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(_vm.dataContainer1.categories, function (category) {
                  return _c(
                    "li",
                    {
                      staticClass: "ddown",
                      on: {
                        click: function ($event) {
                          category.childCount
                            ? _vm.slideTo(category)
                            : _vm.openCategory(category.url)
                        },
                      },
                    },
                    [
                      _c("a", [_vm._v(_vm._s(category.details[0].name))]),
                      _vm._v(" "),
                      _c("span", { staticClass: "nav-direction" }, [
                        _c("i", {
                          staticClass: "fa fa-lg",
                          class: category.childCount
                            ? "fa-chevron-right"
                            : "fa-chevron-circle-right",
                          attrs: { "aria-hidden": "true" },
                        }),
                      ]),
                    ]
                  )
                }),
                _vm._v(" "),
                _vm.dataContainer1.categories[0]
                  ? _vm._l(
                      _vm.dataContainer1.categories[0].siblingCount -
                        _vm.dataContainer1.categories.length,
                      function (number) {
                        return _c("li", { staticClass: "ddown" }, [
                          _c("span", {
                            staticClass: "nav-placeholder m-3",
                            style: { width: Math.random() * 20 + 60 + "%" },
                          }),
                        ])
                      }
                    )
                  : _vm.dataContainer1.parent
                  ? _vm._l(
                      _vm.dataContainer1.parent.childCount,
                      function (number) {
                        return _c("li", { staticClass: "ddown" }, [
                          _c("span", {
                            staticClass: "nav-placeholder m-3",
                            style: { width: Math.random() * 20 + 60 + "%" },
                          }),
                        ])
                      }
                    )
                  : _vm._e(),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "ul",
              {
                directives: [{ name: "menu", rawName: "v-menu" }],
                staticClass: "mainmenu w-100 p-0 m-0",
                attrs: { id: "menu-2" },
              },
              [
                _vm.dataContainer2.parent && _vm.dataContainer2.parent.details
                  ? _c(
                      "li",
                      {
                        staticClass: "ddown bg-secondary",
                        on: {
                          click: function ($event) {
                            return _vm.slideTo(
                              (_vm.dataContainer2.parent &&
                                _vm.dataContainer2.parent.parent) ||
                                null,
                              true
                            )
                          },
                        },
                      },
                      [
                        _vm._m(3),
                        _vm._v(" "),
                        _c("a", [
                          _vm._v(
                            _vm._s(_vm.dataContainer2.parent.details[0].name)
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.dataContainer2.parent && _vm.dataContainer2.parent.url
                  ? _c(
                      "li",
                      {
                        staticClass: "ddown bg-dark",
                        on: {
                          click: function ($event) {
                            return _vm.openCategory(
                              _vm.dataContainer2.parent.url
                            )
                          },
                        },
                      },
                      [_vm._m(4), _vm._v(" "), _vm._m(5)]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(_vm.dataContainer2.categories, function (category) {
                  return _c(
                    "li",
                    {
                      staticClass: "ddown",
                      on: {
                        click: function ($event) {
                          category.childCount
                            ? _vm.slideTo(category)
                            : _vm.openCategory(category.url)
                        },
                      },
                    },
                    [
                      _c("a", [_vm._v(_vm._s(category.details[0].name))]),
                      _vm._v(" "),
                      category.childCount
                        ? _c("span", { staticClass: "nav-direction" }, [
                            _c("i", {
                              staticClass: "fa fa-lg",
                              class: category.childCount
                                ? "fa-chevron-right"
                                : "fa-chevron-circle-right",
                              attrs: { "aria-hidden": "true" },
                            }),
                          ])
                        : _vm._e(),
                    ]
                  )
                }),
                _vm._v(" "),
                _vm.dataContainer2.categories[0]
                  ? _vm._l(
                      _vm.dataContainer2.categories[0].siblingCount -
                        _vm.dataContainer2.categories.length,
                      function (number) {
                        return _c("li", { staticClass: "ddown" }, [
                          _c("span", {
                            staticClass: "nav-placeholder m-3",
                            style: { width: Math.random() * 20 + 60 + "%" },
                          }),
                        ])
                      }
                    )
                  : _vm.dataContainer2.parent
                  ? _vm._l(
                      _vm.dataContainer2.parent.childCount,
                      function (number) {
                        return _c("li", { staticClass: "ddown" }, [
                          _c("span", {
                            staticClass: "nav-placeholder m-3",
                            style: { width: Math.random() * 20 + 60 + "%" },
                          }),
                        ])
                      }
                    )
                  : _vm._e(),
              ],
              2
            ),
          ]
        ),
        _vm._v(" "),
        !_vm.isNavigationInitialized
          ? [
              _c("ul", { staticClass: "breadcrumb" }, [
                _c("li", {
                  staticClass: "btn-close",
                  on: {
                    click: function ($event) {
                      return _vm.closeNavigation()
                    },
                  },
                }),
                _vm._v(" "),
                _vm._m(6),
              ]),
              _vm._v(" "),
              _c("loading-animation"),
            ]
          : _vm._e(),
      ],
      2
    ),
    _vm._v(" "),
    _c("div", {
      staticClass: "mobile-navigation-overlay",
      on: {
        click: function ($event) {
          return _vm.closeNavigation()
        },
      },
    }),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "nav-direction btn-up" }, [
      _c("i", {
        staticClass: "fa fa-lg fa-chevron-left",
        attrs: { "aria-hidden": "true" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", [_c("strong", [_vm._v("Alles")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "nav-direction" }, [
      _c("i", {
        staticClass: "fa fa-lg fa-chevron-circle-right",
        attrs: { "aria-hidden": "true" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "nav-direction btn-up" }, [
      _c("i", {
        staticClass: "fa fa-lg fa-chevron-left",
        attrs: { "aria-hidden": "true" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", [_c("strong", [_vm._v("Alles")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "nav-direction" }, [
      _c("i", {
        staticClass: "fa fa-lg fa-chevron-circle-right",
        attrs: { "aria-hidden": "true" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "breadcrumb-item" }, [
      _c("i", { staticClass: "fa fa-home", attrs: { "aria-hidden": "true" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }