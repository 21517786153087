var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.$data.$_enableCarousel
    ? _c(
        "div",
        {
          staticClass: "carousel slide",
          attrs: { id: "carousel" + _vm._uid, "data-interval": "false" },
        },
        [
          _vm.showDots
            ? _c(
                "ol",
                { staticClass: "carousel-indicators" },
                _vm._l(_vm.imageUrls, function (imageUrl, index) {
                  return _c("li", {
                    class: { active: index === 0 },
                    attrs: {
                      "data-target": "#carousel" + _vm._uid,
                      "data-slide-to": index,
                    },
                  })
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "carousel-inner" },
            _vm._l(_vm.imageUrls, function (imageUrl, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "carousel-item",
                  class: { active: index === 0 },
                },
                [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: _vm.itemUrl,
                        title: _vm.getTitleText(imageUrl),
                      },
                    },
                    [
                      index > 0
                        ? _c("lazy-img", {
                            ref: "itemLazyImage",
                            refInFor: true,
                            attrs: {
                              "picture-class": "img-fluid",
                              "image-url": imageUrl.url,
                              "data-alt": _vm.getAltText(imageUrl),
                            },
                          })
                        : _c("img", {
                            staticClass: "img-fluid",
                            attrs: {
                              src: imageUrl.url,
                              alt: _vm.getAltText(imageUrl),
                            },
                          }),
                    ],
                    1
                  ),
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _vm.showNav
            ? _c(
                "a",
                {
                  staticClass: "carousel-control-prev",
                  attrs: {
                    href: "#carousel" + _vm._uid,
                    role: "button",
                    "data-slide": "prev",
                  },
                },
                [
                  _c("span", {
                    staticClass: "carousel-control-prev-icon",
                    attrs: { "aria-hidden": "true" },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "sr-only" }, [_vm._v("Previous")]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showNav
            ? _c(
                "a",
                {
                  staticClass: "carousel-control-next",
                  attrs: {
                    href: "#carousel" + _vm._uid,
                    role: "button",
                    "data-slide": "next",
                  },
                },
                [
                  _c("span", {
                    staticClass: "carousel-control-next-icon",
                    attrs: { "aria-hidden": "true" },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "sr-only" }, [_vm._v("Next")]),
                ]
              )
            : _vm._e(),
        ]
      )
    : _c(
        "a",
        {
          attrs: {
            href: _vm.itemUrl,
            title: _vm.getTitleText(_vm.imageUrls[0]),
          },
        },
        [
          _c("img", {
            staticClass: "img-fluid",
            attrs: {
              src: _vm._f("itemImage")(_vm.imageUrls),
              alt: _vm.getAltText(_vm.imageUrls[0]),
            },
          }),
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }