var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "nav-link",
      attrs: {
        href: _vm.urlWishList,
        rel: "nofollow",
        "aria-label": _vm.$translate("Ceres::Template.wishList"),
      },
    },
    [
      _c("span", { staticClass: "badge-right mr-1 d-none d-sm-inline" }, [
        _vm._v(_vm._s(_vm.wishListCount)),
      ]),
      _vm._v(" "),
      _c("i", {
        staticClass: "fa",
        class: {
          "fa-heart-o": !_vm.wishListCount,
          "fa-heart": _vm.wishListCount,
        },
        attrs: { "aria-hidden": "true" },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }