var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: { "p-0": _vm.$ceres.isShopBuilder } }, [
    _c(
      "div",
      [
        _c(
          "div",
          { staticClass: "d-flex flex-grow-1 position-relative" },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.searchString,
                  expression: "searchString",
                },
              ],
              ref: "searchInput",
              staticClass: "search-input flex-grow-1 px-3 py-2",
              attrs: {
                type: "search",
                autofocus: _vm.isShopBuilder,
                placeholder: _vm.$translate(
                  "Ceres::Template.headerSearchPlaceholder"
                ),
                "aria-label": _vm.$translate(
                  "Ceres::Template.headerSearchTerm"
                ),
              },
              domProps: { value: _vm.searchString },
              on: {
                input: [
                  function ($event) {
                    if ($event.target.composing) return
                    _vm.searchString = $event.target.value
                  },
                  function ($event) {
                    return _vm.onValueChanged($event.target.value)
                  },
                ],
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.search()
                },
                focus: function ($event) {
                  _vm.isSearchFocused = true
                },
                blur: function ($event) {
                  return _vm.onBlurSearchField($event)
                },
              },
            }),
            _vm._v(" "),
            _vm._t("search-button", function () {
              return [
                _c(
                  "button",
                  {
                    staticClass: "search-submit px-3",
                    attrs: {
                      type: "submit",
                      "aria-label": _vm.$translate(
                        "Ceres::Template.headerSearch"
                      ),
                    },
                    on: {
                      click: function ($event) {
                        return _vm.search()
                      },
                    },
                  },
                  [
                    _c("icon", {
                      staticClass: "fa-fw",
                      attrs: {
                        icon: "search",
                        loading: _vm.autocompleteIsLoading,
                      },
                    }),
                  ],
                  1
                ),
              ]
            }),
          ],
          2
        ),
        _vm._v(" "),
        _vm.isSearchFocused
          ? [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.hasInitialInput || _vm.$ceres.isShopBuilder,
                      expression: "hasInitialInput || $ceres.isShopBuilder",
                    },
                  ],
                },
                [
                  _vm._t("autocomplete-suggestions", function () {
                    return [
                      _c(
                        "div",
                        {
                          staticClass:
                            "autocomplete-suggestions shadow bg-white w-100",
                        },
                        [
                          _c("search-suggestion-item", {
                            attrs: {
                              "show-images": _vm.showItemImages,
                              "suggestion-type": "item",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  }),
                ],
                2
              ),
            ]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }