var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.upSellingItem
    ? _c(
        "tr",
        {
          staticClass: "bx-narrative-item",
          attrs: {
            "data-bx-item-id": "upselling|productid=" + _vm.item.variation.id,
          },
        },
        [
          _c("td", { staticClass: "align-middle" }, [
            _c(
              "a",
              {
                attrs: {
                  href: _vm._f("itemURL")(_vm.item, _vm.urlWithVariationId),
                },
              },
              [
                _c("lazy-img", {
                  staticClass: "d-block xy-75",
                  attrs: {
                    "picture-class": "img-fluid",
                    "image-url": _vm._f("itemImage")(
                      _vm._f("itemImages")(
                        _vm.item.images,
                        _vm.imageUrlAccessor
                      )
                    ),
                    alt: _vm._f("itemName")(_vm.item),
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("td", { staticClass: "align-middle" }, [
            _c(
              "a",
              {
                attrs: {
                  href: _vm._f("itemURL")(_vm.item, _vm.urlWithVariationId),
                },
              },
              [
                _vm._v("\n            " + _vm._s(_vm._f("itemName")(_vm.item))),
                _vm._l(_vm.item.groupedAttributes, function (attribute) {
                  return _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm._f("translate")(
                          "Ceres::Template.itemGroupedAttribute",
                          attribute
                        )
                      )
                    ),
                  ])
                }),
              ],
              2
            ),
          ]),
          _vm._v(" "),
          _c(
            "td",
            { staticClass: "align-middle text-right" },
            [
              _vm._t("before-prices"),
              _vm._v(" "),
              _vm.item.prices.rrp &&
              _vm.item.prices.rrp.price.value > 0 &&
              _vm.item.prices.rrp.price.value >
                _vm.item.prices.default.unitPrice.value
                ? _c("div", {
                    staticClass: "badge badge-danger",
                    domProps: {
                      innerHTML: _vm._s(
                        "-" +
                          Math.round(
                            100 *
                              (1 -
                                _vm.item.prices.default.unitPrice.value /
                                  _vm.item.prices.rrp.unitPrice.value)
                          ) +
                          " %"
                      ),
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.item.prices.rrp &&
              _vm.item.prices.rrp.price.value > 0 &&
              _vm.item.prices.rrp.price.value >
                _vm.item.prices.default.unitPrice.value
                ? _c("span", { staticClass: "d-block old-price mb-1" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("currency")(_vm.item.prices.rrp.price.value)
                      )
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("b", { staticClass: "text-danger" }, [
                _vm._v(
                  _vm._s(
                    _vm._f("currency")(_vm.item.prices.default.unitPrice.value)
                  )
                ),
              ]),
              _vm._v(" "),
              _vm._t("after-prices"),
            ],
            2
          ),
          _vm._v(" "),
          _c("td", { staticClass: "align-middle" }, [
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                staticClass:
                  "form-check-input bx-narrative-item bx-narrative-button",
                attrs: {
                  "data-bx-item-id":
                    "checkbox|upselling=" + _vm.item.variation.id,
                  type: "checkbox",
                  id: "upselling" + _vm._uid,
                },
                domProps: { checked: false },
                on: {
                  change: function ($event) {
                    return _vm.onUpSellingSelected($event.target.checked)
                  },
                },
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "form-check-label sr-only",
                  attrs: { for: "upselling" + _vm._uid },
                },
                [
                  _vm._v(
                    _vm._s(_vm._f("itemName")(_vm.item)) +
                      " " +
                      _vm._s(
                        _vm.$translate("Ceres::Template.singleItemAddToBasket")
                      )
                  ),
                ]
              ),
            ]),
          ]),
        ]
      )
    : _c(
        "article",
        {
          staticClass: "cmp cmp-product-thumb bx-narrative-item",
          attrs: {
            "data-bx-item-id": "productcard|productid=" + _vm.item.variation.id,
          },
        },
        [
          _c(
            "div",
            { staticClass: "thumb-image prop-1-1" },
            [
              _vm._t("item-image", function () {
                return [
                  _c("category-image-carousel", {
                    ref: "categoryImageCarousel",
                    attrs: {
                      "image-urls-data": _vm._f("itemImages")(
                        _vm.item.images,
                        _vm.imageUrlAccessor
                      ),
                      "alt-text": _vm._f("itemName")(_vm.item),
                      "title-text": _vm._f("itemName")(_vm.item),
                      "item-url": _vm._f("itemURL")(
                        _vm.item,
                        _vm.urlWithVariationId
                      ),
                      "enable-carousel":
                        _vm.$ceres.config.item.enableImageCarousel,
                      "disable-carousel-on-mobile": _vm.disableCarouselOnMobile,
                    },
                  }),
                ]
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "special-tags p-2" },
            [
              _c("lazy-img", {
                ref: "itemLazyImage",
                attrs: {
                  "picture-class": "xy-30 mr-2 border rounded",
                  "image-url": _vm.item.item.manufacturer.logo,
                  "data-alt": _vm.item.item.manufacturer.externalName,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm._t("store-special", function () {
            return [
              _vm.storeSpecial ||
              _vm.item.variation.bundleType === "bundle" ||
              _vm.item.item.itemType === "set"
                ? _c("item-store-special", {
                    attrs: {
                      "store-special": _vm.storeSpecial,
                      "recommended-retail-price": _vm.item.prices.rrp,
                      "variation-retail-price": _vm.item.prices.default,
                      "special-offer-price": _vm.item.prices.specialOffer,
                      "decimal-count": _vm.decimalCount,
                      "bundle-type": _vm.item.variation.bundleType,
                      "item-type": _vm.item.item.itemType,
                    },
                  })
                : _vm._e(),
            ]
          }),
          _vm._v(" "),
          _vm._t("item-details", function () {
            return [
              _c(
                "div",
                { staticClass: "thumb-content" },
                [
                  _c(
                    "a",
                    {
                      staticClass: "thumb-title",
                      class: {
                        "stretched-link":
                          _vm.$ceres.config.global.shippingCostsCategoryId == 0,
                      },
                      attrs: {
                        href: _vm._f("itemURL")(
                          _vm.item,
                          _vm.urlWithVariationId
                        ),
                      },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm._f("itemName")(_vm.item))
                      ),
                      _vm._l(_vm.item.groupedAttributes, function (attribute) {
                        return _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("translate")(
                                "Ceres::Template.itemGroupedAttribute",
                                attribute
                              )
                            )
                          ),
                        ])
                      }),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "thumb-meta" },
                    [
                      _vm._t("before-prices"),
                      _vm._v(" "),
                      _c("add-to-basket", {
                        attrs: {
                          "variation-id": _vm.item.variation.id,
                          "is-salable":
                            !!_vm.item.filter && _vm.item.filter.isSalable,
                          "has-children":
                            !!_vm.item.item &&
                            _vm.item.item.salableVariationCount > 1,
                          "interval-quantity":
                            _vm.item.variation.intervalOrderQuantity || 1,
                          "minimum-quantity":
                            _vm.item.variation.minimumOrderQuantity,
                          "maximum-quantity":
                            !!_vm.item.variation.maximumOrderQuantity &&
                            _vm.item.variation.maximumOrderQuantity > 0
                              ? _vm.item.variation.maximumOrderQuantity
                              : null,
                          "order-properties": _vm.item.properties.filter(
                            function (prop) {
                              return prop.property.isOderProperty
                            }
                          ),
                          "has-order-properties": _vm.item.hasOrderProperties,
                          "has-required-order-property":
                            _vm.item.hasRequiredOrderProperty,
                          "use-large-scale": false,
                          "show-quantity": false,
                          "item-url": _vm._f("itemURL")(
                            _vm.item,
                            _vm.urlWithVariationId
                          ),
                          "has-price": _vm._f("hasItemDefaultPrice")(_vm.item),
                          "variation-price":
                            _vm.item.prices.default.unitPrice.value,
                          "old-price":
                            _vm.item.prices.rrp &&
                            _vm.item.prices.rrp.price.value > 0 &&
                            _vm.item.prices.rrp.price.value >
                              _vm.item.prices.default.unitPrice.value
                              ? _vm.item.prices.rrp.price.value
                              : null,
                          "is-available": _vm.itemAvailable,
                          "availability-text": _vm.availabilityText,
                          "item-type": _vm.item.item.itemType,
                        },
                      }),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm._t("after-prices"),
                ],
                2
              ),
            ]
          }),
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }