var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasLabel || _vm.isBundle || _vm.isSet
    ? _c("div", { staticClass: "special-tags p-2" }, [
        _vm.hasLabel
          ? _c("span", { staticClass: "badge", class: _vm.tagClass }, [
              _vm._v("\n        " + _vm._s(_vm.label) + "\n    "),
            ])
          : _vm.isBundle
          ? _c("span", { class: _vm.tagClasses.itemBundle }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$translate("Ceres::Template.itemBundle")) +
                  "\n    "
              ),
            ])
          : _vm.isSet
          ? _c("span", { class: _vm.tagClasses.itemSet }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$translate("Ceres::Template.itemSet")) +
                  "\n    "
              ),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }