var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { ref: "handle", staticClass: "popper-handle" },
      [
        _vm._t("handle", function () {
          return [_vm._m(0)]
        }),
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        ref: "node",
        staticClass: "popover bs-popover-auto",
        class: _vm.classNames,
      },
      [
        _c("h3", { staticClass: "popover-header" }, [_vm._t("title")], 2),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "popover-body",
            class: _vm.bodyClass,
            style: _vm.bodyStyle,
          },
          [_vm._t("content")],
          2
        ),
        _vm._v(" "),
        _c("div", { ref: "arrow", staticClass: "arrow" }),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("button", { staticClass: "btn btn-icon btn-secondary btn-sm" }, [
      _c("i", { staticClass: "fa fa-info" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }