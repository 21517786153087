<script>
export default {

    name: "tab-item",

  render(createElement)
  {
    return createElement(
      "div",
      {
        staticClass: "tab-pane bx-narrative-item bx-narrative-button",
        class: {
          active: this.localActive
        },
        attrs: {
          role: "tabpanel",
          "data-bx-item-id": `tabcontent=${this.title}`
        }
      },
      [this.$slots.default]
    );
  },

    props: {
        active:
        {
            type: Boolean,
            default: false
        },
        title:
        {
            type: String,
            default: null
        },
        dataBuilderClickable:
        {
            type: Boolean,
            default: false
        }
    },

    data()
    {
        return {
            localActive: this.active
        };
    },

    methods:
    {
        setActive(isActive)
        {
            this.localActive = isActive;
        }
    }
}
</script>
